export default class FeedsAPI {
	constructor($axios) {
    	this.baseURL = process.env.BACKEND_BASE_URL;
		this.vendorDiscoveryPath = process.env.VENDOR_DISCOVERY_PATH;
		this.$axios = $axios
	}

	getHomeFeeds(params, categories) {
		let param = params
		if(categories) { params.categories = categories }
		return this.$axios.request({
			'url': `${this.baseURL}/${this.vendorDiscoveryPath}/feeds?allcategories=true`,
			'method': 'GET',
			'params': param,
			'headers': {
				'Content-Type' : 'application/json'
			}
		})
	}
	getFilteredCategory(categoryId, params) {
		return this.$axios.request({
			'url': `${this.baseURL}/${this.vendorDiscoveryPath}/feeds/${categoryId}`,
			'method': 'GET',
			'params': params,
			'headers': {
				'Content-Type' : 'application/json'
			}
		})
	}
	getCities(params) {
		return this.$axios.request({
			'url': `${this.baseURL}/${this.vendorDiscoveryPath}/cities`,
			'method': 'GET',
			'params' : params,
			'headers': {
				'Content-Type' : 'application/json'
			}
		})
	}
}
