import Cookie from "js-cookie"

export default class VendorAPI {
	constructor($axios) {
		this.baseURL = process.env.BACKEND_BASE_URL
		this.dealsPath = process.env.DEALS_PATH
		this.vendorListingPath = "ms/vendor-listing/api"
		this.bsVendorPath = "ms/bs-vendors/api"
		this.$axios = $axios
	}

	getVendorDetail(id, type) {
		let attr_type = type ? type : "id"
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/ms/bs-vendors/api/v1/proxy_vendors/${id}`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getProxyVendorDetail(id_or_slug, type, filters = "") {
		let attr_type = type ? type : "id"
		let params = {
			attr_type: attr_type
		}

		if (filters) {
			params.filter = filters
		}

		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.bsVendorPath}/v1/proxy_vendors/${id_or_slug}`,
			params: params,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getFlexiVendorDetail(id_or_slug) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.bsVendorPath}/v1/vendors/${id_or_slug}`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getVendorMeetingDate(vendorId, startDate, endDate) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.bsVendorPath}/v1/vendors/${vendorId}/virtual_meeting_schedule_dates`,
			params: {
				start_date: startDate,
				end_date: endDate
			},
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getVendorMeetingTime(vendorId, date) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.bsVendorPath}/v1/vendors/${vendorId}/virtual_meeting_schedules`,
			params: {
				schedule_date: date
			},
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	bookOnlineMeeting(data) {
		let _data = {
			user_email: data.user_email,
			user_phone_number: "+62" + data.user_phone_number
		}
		return this.$axios.request({
			method: "POST",
			url: `${this.baseURL}/${this.bsVendorPath}/v1/virtual_meeting_schedules/${data.schedule_id}/book`,
			data: _data,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	sendReport({ reason, description, type, reportedId }) {
		return this.$axios.request({
			url: `${this.baseURL}/v2/reports`,
			method: "POST",
			data: {
				reason,
				description,
				type,
				reportedId
			},
			headers: {
				"Content-Type": "application/json",
				"Session-Id": Cookie.get("BSID")
			}
		})
	}

	isVendorSaved(vendorId) {
		return this.$axios.request({
			url: `${this.baseURL}/v2/save_vendor/saved_vendor_ids/${vendorId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Session-Id": Cookie.get("BSID")
			}
		})
	}

	getSavedVendors() {
		return this.$axios.request({
			url: `${this.baseURL}/v2/save_vendor`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Session-Id": Cookie.get("BSID")
			}
		})
	}

	sendMessage({ productId, message, BSID }) {
		const url = `${this.baseURL}/${this.dealsPath}/deals/${productId}/inquiry`
		const headers = {
			"Content-Type": "application/json",
			Authorization: BSID
			// ...this.utmData
		}
		return this.$axios.request({
			method: "POST",
			url,
			data: {
				message: message
			},
			headers: headers
		})
	}

	getLastSeen(uuid, accountId) {
		const url = `${this.baseURL}/v2/accounts/last_seen/${uuid}/${accountId}`
		return this.$axios.request({
			method: "GET",
			url,
			headers: {
				...this.utmData
			}
		})
	}
	igProject(att) {
		const url = `${this.baseURL}/ms/backend-flask/api/v1/project/${att}?attr_type=slug&bs-localization-bucket=ID&bs-translation-bucket=en`
		return this.$axios.request({
			method: "GET",
			url,
			headers: {
				...this.utmData
			}
		})
	}

	getProjectByVendor(vendorId, params) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/ms/backend-flask/api/v1/vendor/${vendorId}/projects`,
			params: params,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	/*
	 *	action: [get_phone, get_website, get_email, get_facebook, get_instagram, get_pricelist
	 			message, save_vendor, get_twitter]
	 */
	action(slugOrId, type) {
		let url = `${this.baseURL}/v2/vendors/` + slugOrId + "/action"
		let params = {
			type: type
		}
		let headers = {
			"Content-Type": "application/json",
			"Session-Id": Cookie.get("BSID"),
			...this.utmData
		}

		return this.$axios.request({
			method: "GET",
			url: url,
			params: params,
			headers: headers
		})
	}

	getFlexiVendor(next) {
		let params = {
			next: next
		}
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/ms/feeds/api/v1/vendors?is_flexi=1&order=featured&bs-localization-bucket=ID&bs-translation-bucket=id`,
			params: params,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	checkVendorEvent(vendorId) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/ms/bs-vendors/api/v1/vendors/${vendorId}/events`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getFlexiCatetgories() {
		return this.$axios.request({
			method: "GET",
			url: `https://www.bridestory.com/static-api/flexi/attributions.json`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getVendorList(attr, feedsUrl) {
		let params = {
			page: attr.page,
			size: attr.size
		}
		if (attr.keywords) params.keywords = attr.keywords
		if (attr.events) params.events = attr.events
		if (attr.attribution_id) params.attribution_id = attr.attribution_id
		if (attr.badge) params.badge = attr.badge
		if (attr.is_flexi && attr.is_flexi != 0 && attr.is_flexi != false)
			params.is_flexi = attr.is_flexi
		if (attr.category) params.category = attr.category
		if (attr.city) params.city = attr.city
		if (attr.budget) params.budget = attr.budget
		if (attr.seed) params.seed = attr.seed
		if (attr.is_include_ads) params.is_include_ads = attr.is_include_ads
		if (attr.is_mobile) params.is_mobile = attr.is_mobile
		if (attr.next) params.next = attr.next

		let BACKEND_URL = `${this.baseURL}/${this.vendorListingPath}/v1/vendors`

		if (feedsUrl) BACKEND_URL = `${this.baseURL}/ms/feeds/api/v1/vendors`

		return this.$axios.request({
			method: "GET",
			url: BACKEND_URL,
			params: params,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getVendorAttributionList(attr) {
		let params = {
			page: attr.page
		}

		if (attr.badge) params.badge = attr.badge
		if (attr.category) params.category = attr.category
		if (attr.city) params.city = attr.city
		if (attr.budget) params.budget = attr.budget

		const BACKEND_URL = `${this.baseURL}/ms/bs-vendors/api/v2/vendor-attribution/${attr.attribution_id}`

		return this.$axios
			.request({
				method: "GET",
				url: BACKEND_URL,
				params: params,
				headers: {
					"Content-Type": "application/json"
				}
			})
			.then(response => {
				return response.data
			})
	}

	setView(slug) {
		return this.$axios.request({
			method: "POST",
			url: `${this.baseURL}/v2/views/vendors/${slug}?type=slug`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	checkVendorsActiveness(vendorId) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/ms/bs-vendors/api/v1/vendors/${vendorId}/activeness`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	checkVendorsBlacklist(vendorSlug) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/ms/bs-vendors/api/v1/vendors/blacklist/${vendorSlug}`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getVendorAttributionDetail(attribution_slug) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/ms/bs-vendors/api/v1/attributions/${attribution_slug}`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}
}
