<template>
	<div class="attribution">
		<div class="attribution__header">
			<h2 class="attribution__text attribution__text--title">{{ title ? title : $t('Deal.attribution_title') }}</h2>
		</div>
		<div class="attribution__content">
			<div v-swiper:mySwiper="swiperOption">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="card in cards" :key="card.id">
						<store-attribution-card :attribution="card" />
					</div>
				</div>
			</div>
			<img 
				:src="getImageUrl('https://alexandra.bridestory.com/image/upload/assets/swiper-arrow-prev-r1xkhd4Q_.png', 80, 80).imgSrc" 
				:id="`swiper-attribution-prev`" 
				alt="button-prev" 
				slot="button-prev"  
				class="swiper-attribution-prev" 
			/>
			<img 
				:src="getImageUrl('https://alexandra.bridestory.com/image/upload/assets/swiper-arrow-next-BJTCoONmu.png', 80, 80).imgSrc" 
				:id="`swiper-attribution-next`" 
				alt="button-next" 
				slot="button-next" 
				class="swiper-attribution-next" 
			/>
		</div>
	</div>
</template>

<script>
import StoreAttributionCard from "@/components/cards/attribution/index.vue";

import imageHelper from "@/helpers/imageHelper";

import '~/plugins/swiper';

export default {
	name: "DiscoveryAttribution",
	mixins: [imageHelper],
	props: {
		title: {
			type: String,
			required: true
		},
		cards: {
			type: Array,
			required: true
		}
	},
	components: {
		StoreAttributionCard
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 8,
				spaceBetween: 20,
				autoHeight: true,
				navigation: {
					nextEl: ".swiper-attribution-next",
					prevEl: ".swiper-attribution-prev"
				},
				breakpoints: {
					1700: {
						slidesPerView: 7,
					},
					1550: {
						slidesPerView: 6,
					},
					1350: {
						slidesPerView: 5,
					},
					1150: {
						slidesPerView: 4,
					},
					1023: {
						slidesPerView: "auto",
						spaceBetween: 10,
						slidesOffsetBefore: 14,
						slidesOffsetAfter: 14,
						navigation: false
					}
				}
			}
		}
	}
}
</script>

<style lang="scss" src="./style.scss" scoped></style>