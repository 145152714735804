<template>
	<div class="image-slider">
		<div v-swiper:mySwiper="swiperOption">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(image, i) in images" :key="i">
					<a :href="image.targetHref" @click="trackBanner(image.title)">
						<img
							:src="getImageUrl(image.imageUrl).imgSrc" 
							:alt="getImageName(image.imageUrl)" 
							:sliderTitle="image.title"
							:sliderId="image.id"
						/>
					</a>
				</div>
			</div>
			<img 
				:src="getImageUrl('https://alexandra.bridestory.com/image/upload/assets/swiper-arrow-prev-r1xkhd4Q_.png', 80, 80).imgSrc" 
				:id="`swiper-banner-prev`" 
				alt="button-prev" 
				slot="button-prev"  
				class="swiper-banner-prev" 
			/>
			<img 
				:src="getImageUrl('https://alexandra.bridestory.com/image/upload/assets/swiper-arrow-next-BJTCoONmu.png', 80, 80).imgSrc" 
				:id="`swiper-banner-next`" 
				alt="button-next" 
				slot="button-next" 
				class="swiper-banner-next" 
			/>
			<div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
		</div>
	</div>
</template>

<script>
import imageHelper from "@/helpers/imageHelper";
import string from '@/helpers/string';

import '~/plugins/swiper';

export default {
  	name: "imageSlider",
  	props: ['images', 'widthImg', 'heightImg'],
	mixins: [string, imageHelper],
	watch:{
		heightImg: function(newVal, oldVal){
			this.heightImg = newVal
		}
	},
	data() {
	  	return {
	    	swiperOption: {
		    	autoplay: true,
		    	loop: true,
		    	spaceBetween: 20,
		  		slidesPerView: 'auto',
		  		breakpointsInverse: true,
		  		breakpoints: {
		  			768: {
						centeredSlides: true,
						navigation: {
							nextEl: '.swiper-banner-next',
							prevEl: '.swiper-banner-prev'
						},
		  			}
		  		},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
					dynamicBullets: true
				},
				on: {
					click:	function(d) {
						let slider = d.target.attributes
						if (slider.sliderTitle && slider.sliderId) {
							const sliderTitle = slider.sliderTitle && slider.sliderTitle.nodeValue
							const sliderId = slider.sliderId && slider.sliderId.nodeValue

							window.dataLayer = window.dataLayer || [];
							window.dataLayer.push({
								event: 'v2_banner_click',
								v2_bannerId: sliderTitle + ';' + sliderId
							});
						}
					}
				}
		    }
	  	}
	},
	methods: {
		trackBanner(title) {
			dataLayer.push({
				discovery: {
					category: 'discovery',
					action: 'click_banner',
					label: title
				}
			})
		}
	},
	mounted() {
		this.$nextTick(() => { this.mySwiper.el.style.opacity = 1 });
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />