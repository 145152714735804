<template>
	<nuxt-link :to="localeLink(`${attributionPath}`)" >
		<div class="card">
			<image-style
				class="card__image"
				:src="attribution.cover"
				:width="widthImg"
				:height="heightImg"
				:isLazyLoad="true"
				:fetch_format="'webp'"
				:alt="attribution.name"
			/>
			<div class="card__header">
				<div class="card__line"></div>
				<h3 class="card__text card__text--title">
					{{ attribution.name }}
				</h3>
				<p v-show="minimumPrice" class="card__text card__text--detail">{{$t('Deal.start_from')}} IDR {{minimumPrice}}</p>
			</div>
		</div>
	</nuxt-link>
</template>

<script>
import ImageStyle from "@/components/decorators/imageStyle.vue";

export default {
	name: "AttributionCard",
	props: {
		attribution: {
			type: Object,
			required: true
		}
	},
	components: {
		"image-style": ImageStyle
	},	
	data() {
		return {
			widthImg: null,
			heightImg: null,
			attributionPath: this.attribution.path ? this.attribution.path : 'store/attribution/' + this.attribution.slug
		}
	},
	computed: {
		minimumPrice() {
			return this.attribution.minimum_price && parseInt(this.attribution.minimum_price).toLocaleString()
		}
	},
	beforeMount() {
		this.widthImg = window.innerWidth > 767 ? 197 : 125;
		this.heightImg = window.innerWidth > 767 ? 295 : 188;;
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />