var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-slider",attrs:{"id":"banner-slider-desktop"}},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper",value:(_vm.swiperOption),expression:"swiperOption",arg:"mySwiper"}]},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.banners),function(banner,i){return _c('div',{key:i,staticClass:"swiper-slide",attrs:{"data-tracking-id":banner.id}},[_c('a',{attrs:{"href":banner.target_url,"target":"_blank"},on:{"click":function($event){return _vm.handleClick(banner)}}},[_c('img',{attrs:{"src":_vm.getImageUrl(banner.image_url, 576, 192).imgSrc,"alt":_vm.getImageName(banner.image_url),"sliderTitle":banner.label,"sliderId":banner.id}})])])}),0),_vm._v(" "),_c('img',{staticClass:"swiper-banner-prev",attrs:{"slot":"button-prev","src":_vm.getImageUrl(
					'https://alexandra.bridestory.com/image/upload/assets/swiper-arrow-prev-r1xkhd4Q_.png',
					80,
					80
				).imgSrc,"id":"swiper-banner-prev","alt":"button-prev"},slot:"button-prev"}),_vm._v(" "),_c('img',{staticClass:"swiper-banner-next",attrs:{"slot":"button-next","src":_vm.getImageUrl(
					'https://alexandra.bridestory.com/image/upload/assets/swiper-arrow-next-BJTCoONmu.png',
					80,
					80
				).imgSrc,"id":"swiper-banner-next","alt":"button-next"},slot:"button-next"}),_vm._v(" "),_c('div',{staticClass:"swiper-pagination swiper-pagination-bullets",attrs:{"slot":"pagination"},slot:"pagination"})])])}
var staticRenderFns = []

export { render, staticRenderFns }