import Cookie from 'js-cookie';

export default class MiscAPI {
	constructor($axios) {
    	this.baseURL = process.env.BACKEND_BASE_URL;
		this.vendorDiscoveryPath = process.env.VENDOR_DISCOVERY_PATH;
		this.$axios = $axios
		if (process.server) { 
			this.$axios.defaults.headers.common['Cookie'] = ''
		}
	}

	searchCity(name) {
		return this.$axios.request({
			'url': `${this.baseURL}/${this.vendorDiscoveryPath}/cities?search=${name}`,
			'method': 'GET',
			'headers': {
				'Content-Type' : 'application/json'
			}
		})
	}

	getCategories() {
		return this.$axios.request({
			'url': `${this.baseURL}/${this.vendorDiscoveryPath}/categories`,
			'method': 'GET',
			'headers': {
				'Content-Type' : 'application/json'
			}
		})
	}

	setCategories(data) {
		let payload = {
			categories: data
		}
		return this.$axios.request({
			'url'		: `${this.baseURL}/${this.vendorDiscoveryPath}/categories`,
			'method'	: 'PUT',
			'data'		: JSON.stringify(payload),
			'headers' 	: {
				'Content-Type' : 'application/json'
			}
		})
	}

	getGeoLocation () {
	    return this.$axios.request({
	      url : `${this.baseURL}/v2/geo_location/detect`,
	      method : 'GET',
		  headers: {
			  ...this.utmData
		  }
	    });
	}

	getBudgets() {
		return this.$axios.request({
			'url': `${this.baseURL}/${this.vendorDiscoveryPath}/budgets`,
			'method': 'GET',
			'headers': {
				'Content-Type' : 'application/json'
			}
		})
	}

	getBanners(param, ssrCookies) {
		if (!ssrCookies) {
			param['bs-localization-bucket'] = Cookie.get("BS_localization") || 'ID'
			param['bs-translation-bucket'] = Cookie.get("BS_PreferredLang") || 'en'
		}else{
			param['bs-localization-bucket'] =  ssrCookies.localization
			param['bs-translation-bucket'] =  ssrCookies.translation
		}

		return this.$axios.request({
			'url': `${this.baseURL}/v2/banner`,
			'method': 'GET',
			'params': param,
			'headers': {
				'Content-Type' : 'application/json'
			}
		})
	}

	getCovidData() {
		return this.$axios.request({
            'url': `https://services5.arcgis.com/VS6HdKS0VfIhv8Ct/arcgis/rest/services/Statistik_Perkembangan_COVID19_Indonesia/FeatureServer//0/query?f=json&where=Jumlah_Kasus_Baru_per_Hari%20%3E%200&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outFields=*&orderByFields=Tanggal%20desc&resultOffset=0&resultRecordCount=1`,			'method': 'GET',
			'headers': {
				'Content-Type' : 'application/json',
				'Access-Control-Allow-Origin': 'https://crossorigin.me/'
			}
		})
	} 

	_getCovidData() {
		return this.$axios.request({
			'url': `https://api.kawalcovid19.id/v1/api/case/summary`,
			'method': 'GET',
			'headers': {
				'Content-Type' : 'application/json',
				'Access-Control-Allow-Origin': 'https://crossorigin.me/'
			}
		})
	} 
}
