<template>
	<div id="banner-slider-desktop" class="image-slider">
		<div v-swiper:mySwiper="swiperOption">
			<div class="swiper-wrapper">
				<div
					class="swiper-slide"
					v-for="(banner, i) in banners"
					:key="i"
					:data-tracking-id="banner.id"
				>
					<a
						:href="banner.target_url"
						target="_blank"
						@click="handleClick(banner)"
					>
						<img
							:src="
								getImageUrl(banner.image_url, 576, 192).imgSrc
							"
							:alt="getImageName(banner.image_url)"
							:sliderTitle="banner.label"
							:sliderId="banner.id"
						/>
					</a>
				</div>
			</div>
			<img
				:src="
					getImageUrl(
						'https://alexandra.bridestory.com/image/upload/assets/swiper-arrow-prev-r1xkhd4Q_.png',
						80,
						80
					).imgSrc
				"
				:id="`swiper-banner-prev`"
				alt="button-prev"
				slot="button-prev"
				class="swiper-banner-prev"
			/>
			<img
				:src="
					getImageUrl(
						'https://alexandra.bridestory.com/image/upload/assets/swiper-arrow-next-BJTCoONmu.png',
						80,
						80
					).imgSrc
				"
				:id="`swiper-banner-next`"
				alt="button-next"
				slot="button-next"
				class="swiper-banner-next"
			/>
			<div
				class="swiper-pagination swiper-pagination-bullets"
				slot="pagination"
			></div>
		</div>
	</div>
</template>

<script>
import imageHelper from "@/helpers/imageHelper"
import string from "@/helpers/string"

import "~/plugins/swiper"

export default {
	name: "imageSlider",
	props: ["banners", "autoPlay"],
	mixins: [string, imageHelper],
	data() {
		const _this = this
		let autoPlay = this.autoPlay ? this.autoPlay : false
		return {
			swiperOption: {
				autoplay: autoPlay,
				loop: true,
				spaceBetween: 20,
				slidesPerView: "auto",
				breakpointsInverse: true,
				breakpoints: {
					768: {
						centeredSlides: true,
						navigation: {
							nextEl: ".swiper-banner-next",
							prevEl: ".swiper-banner-prev"
						}
					}
				},
				pagination: {
					el: ".swiper-pagination",
					type: "bullets",
					clickable: true,
					dynamicBullets: true
				},
				on: {
					click: function(d) {
						let slider = d.target.attributes
						if (slider.sliderTitle && slider.sliderId) {
							const sliderTitle =
								slider.sliderTitle &&
								slider.sliderTitle.nodeValue
							const sliderId =
								slider.sliderId && slider.sliderId.nodeValue

							window.dataLayer = window.dataLayer || []
							window.dataLayer.push({
								event: "v2_banner_click",
								v2_bannerId: sliderTitle + ";" + sliderId
							})
						}
					},
					slideChangeTransitionEnd: function() {
						let banner = _this.banners[this.realIndex]
						if (
							this.slides[this.realIndex].getAttribute(
								"data-tracking-id"
							)
						) {
							_this.handleSlide(banner)
							this.slides[this.realIndex].removeAttribute(
								"data-tracking-id"
							)
						}
					}
				}
			}
		}
	},
	methods: {
		handleClick(data) {
			this.$emit("onClick", data)
		},
		handleSlide(data) {
			this.$emit("onSlide", data)
		},
		trackBanner(title) {
			dataLayer.push({
				discovery: {
					category: "discovery",
					action: "click_banner",
					label: title
				}
			})
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.mySwiper.el.style.opacity = 1

			document
				.getElementById("banner-slider-desktop")
				.addEventListener("mouseenter", () => {
					this.mySwiper.autoplay.pause()
				})
			document
				.getElementById("banner-slider-desktop")
				.addEventListener("mouseleave", () => {
					this.mySwiper.autoplay.run()
				})
		})
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />
