<template>
	<div class="vendor-discovery">
		<div class="gridspan">
			<h2 class="mr-10">{{ $t('vendorDiscovery.my_vendor_discovery_label') }}</h2>
			<div class="dropdown" v-if="widthBrowser <= 1023" @click="pickerMobile">
				<i class="icon-dots-filled"></i>
			</div>
			<div class="dropdown" v-else @click="openPopupCategory()">
				<i class="icon-dots-filled discovery-manage-categories"></i>
			</div>
			<div class="gridcol-1-1 discovery-desktop">
				<div class="categories" :key=index v-for="(category, index) in selectedCategories" @click="goToCategorySection(category.slug)">{{ category.name }}</div>
			</div>
			<div v-swiper:mySwiper="categorySwiper" class="discovery-slider">
				<div class="swiper-wrapper">
					<div class="swiper-slide categories" :key=index v-for="(category, index) in selectedCategories" @click="goToCategorySection(category.slug)">
						{{ category.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import '~/plugins/swiper';

export default {
	name: "vendorDiscovery",
	props: ["selectedCategories"],
	data() {
		return {
			categorySwiper: {
				slidesPerView: 3,
				spaceBetween: 10,
         		slidesOffsetBefore: 15,
         		slidesOffsetAfter: 25
			},
			widthBrowser : 0
		}
	},
	methods: {
		toBottom(){
			window.scrollTo({
				top: document.body.scrollHeight,
				behavior: "smooth"
			});
		},
		goToCategorySection(slug) {
			this.$emit('goToCategorySection', slug);
		},
		openPopupCategory() {
			this.$emit("openPopupCategory");
		},
		pickerMobile () {
			this.openPopupCategory();
			this.$store.dispatch('setCatPicker', true)
		}
	},
	mounted() {
		this.widthBrowser = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />