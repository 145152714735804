var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bslider-wrapper"},[_vm._l((_vm.banners),function(banner,index){return _c('input',{key:index,attrs:{"type":"radio","name":"next","id":'slide' + (index + 1)},domProps:{"checked":index == _vm.i},on:{"click":function($event){return _vm.handleButClick(index)}}})}),_vm._v(" "),_c('div',{staticClass:"container",attrs:{"id":"see"}},[_c('div',{staticClass:"image",style:(_vm.containerStyle)},_vm._l((_vm.banners),function(banner,index){return _c('a',{key:index,style:('width:' + 100 / _vm.banners.length + '%'),attrs:{"data-banner-index":index},on:{"click":function($event){return _vm.handleClick(banner)}}},[_c('img',{attrs:{"src":_vm.getImageUrl(
							banner.image_url,
							index == _vm.i ? 800 : 300,
							null,
							80,
							'false'
						).imgSrc,"width":_vm.width,"alt":_vm.getImageName(banner.image_url)}})])}),0),_vm._v(" "),_c('div',{staticClass:"but-wrapper"},[_c('div',{staticClass:"but",style:(_vm.buttonStyle)},_vm._l((_vm.banners),function(banner,index){return _c('label',{key:index,staticClass:"select_but",class:{
						checked: index == _vm.i,
						neighbor: Math.abs(index - _vm.i) == 1
					},attrs:{"for":'slide' + (index + 1)}})}),0)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }