export default class AdsAPI {
	constructor($axios) {
    	this.baseURL = process.env.BACKEND_BASE_URL;
		this.advertisementPath = 'ms/bs_ads/api/v1';
		this.$axios = $axios
	}

	sendConversion(data) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.advertisementPath}/conversions`,
			method: "POST",
			data: data,
			headers: {
				"Content-Type" : "application/json"
			}
		})
	}

	sendImpression(data) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.advertisementPath}/impressions`,
			method: "POST",
			data: data,
			headers: {
				"Content-Type" : "application/json"
			}
		})
	}
}
