<template>
	<div class="bslider-wrapper">
		<!-- INPUT FOR BUTTON SELECT SLIDE -->
		<input
			v-for="(banner, index) in banners"
			:key="index"
			type="radio"
			name="next"
			:id="'slide' + (index + 1)"
			:checked="index == i"
			@click="handleButClick(index)"
		/>
		<!-- CONTAINER -->
		<div class="container" id="see">
			<!-- IMAGE -->
			<div class="image" :style="containerStyle">
				<a
					v-for="(banner, index) in banners"
					:key="index"
					:style="'width:' + 100 / banners.length + '%'"
					@click="handleClick(banner)"
					:data-banner-index="index"
				>
					<img
						:src="
							getImageUrl(
								banner.image_url,
								index == i ? 800 : 300,
								null,
								80,
								'false'
							).imgSrc
						"
						:width="width"
						:alt="getImageName(banner.image_url)"
					/>
				</a>
			</div>

			<!-- BOTTOM LINE -->
			<div class="but-wrapper">
				<div class="but" :style="buttonStyle">
					<label
						v-for="(banner, index) in banners"
						:key="index"
						:for="'slide' + (index + 1)"
						class="select_but"
						:class="{
							checked: index == i,
							neighbor: Math.abs(index - i) == 1
						}"
					>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getImageName } from "@/helpers/stringHelper"
import imageHelper from "@/helpers/imageHelper"

export default {
	name: "bsSLider",
	props: ["banners", "rounded"],
	mixins: [imageHelper],
	data() {
		return {
			height: 120,
			width: 400,
			x0: null,
			i: 0,
			tx: "0px",
			btx: "0px",
			btnWidth: 18,
			btnWrapperWidth: 90,
			locked: false
		}
	},
	computed: {
		isSupportWebp() {
			return this.$store.state.system.isWebp
		},
		containerStyle() {
			return {
				transform: `translate3d(calc(${this.tx} + ${this.i}/${this.banners.length}*-100%), 0px, 0px)`,
				width: 100 * this.banners.length + "%"
			}
		},
		buttonStyle() {
			return {
				transform: `translateX(${this.btnWrapperWidth / 2 -
					this.btnWidth / 2 -
					this.i * this.btnWidth}px)`
			}
		}
	},
	methods: {
		handleClick(data) {
			this.$emit("onClick", data)

			setTimeout(() => {
				window.location.href = data.target_url
			}, 300)
		},
		handleSlide(data) {
			this.$emit("onSlide", data)
		},
		handleButClick(index) {
			this.i = index
			this.handleSlide(banners[index])
		},
		openImageFullscreen() {
			this.$emit("onClick", true, this.i)
		},
		getPerfectSize() {
			if (window.innerWidth < 769) {
				this.width = window.innerWidth
			} else {
				this.width = 640
				this.height = 320
			}
		},
		getImageName(name) {
			return getImageName(name)
		},
		unify(e) {
			return e.changedTouches ? e.changedTouches[0] : e
		},
		lock(e) {
			this.x0 = this.unify(e).clientX
			this.locked = true
		},
		move(e) {
			if (this.locked) {
				let dx = this.unify(e).clientX - this.x0,
					s = Math.sign(dx)
				if (
					(this.i > 0 || s < 0) &&
					(this.i < this.banners.length - 1 || s > 0)
				)
					this.i -= s
				this.tx = "0px"
				this.x0 = null
				this.locked = false
				this.getPerfectSize()
				this.handleSlide(this.banners[this.i])
			}
		},
		drag(e) {
			e.preventDefault()

			if (this.locked) {
				this.tx = `${Math.round(this.unify(e).clientX - this.x0)}px`
			}
		}
	},
	destroyed() {
		window.removeEventListener("resize", this.getPerfectSize)
		window.removeEventListener("orientationchange", this.getPerfectSize)

		let _C = document.querySelector(".image")
		_C.removeEventListener("mousedown", this.lock, false)
		_C.removeEventListener("touchstart", this.lock, false)

		_C.removeEventListener("mousemove", this.drag, false)
		_C.removeEventListener("touchmove", this.drag, false)

		_C.removeEventListener("mouseup", this.move, false)
		_C.removeEventListener("touchend", this.move, false)
		// _C.removeEventListener('click', this.openImageFullscreen, false);
	},
	mounted() {
		let btnWrapper = document.getElementsByClassName("but-wrapper")[0]
		this.btnWrapperWidth = btnWrapper && btnWrapper.offsetWidth
		window.addEventListener("resize", this.getPerfectSize)
		window.addEventListener("orientationchange", this.getPerfectSize)

		let _C = document.querySelector(".image")

		_C.addEventListener("mousedown", this.lock, false)
		_C.addEventListener("touchstart", this.lock, false)

		_C.addEventListener("mousemove", this.drag, false)
		_C.addEventListener("touchmove", this.drag, false)

		_C.addEventListener("mouseup", this.move, false)
		_C.addEventListener("touchend", this.move, false)
		// _C.addEventListener('click', this.openImageFullscreen, false);
		this.handleSlide(this.banners[0])
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />
