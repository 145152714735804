<template>
	<div id="discovery-page" class="contain-menu-bottom">
		<div class="container">
			<BannerSlider pageName='discovery' :autoPlay="true"/>
				
			<mobile-overview v-if="$device.isMobile && account && account.type == 'user'"/>
			<div class="clearfix"></div>

			<div class="big-wrapper">
				<vendor-discovery-component
					class="tutorial-step-1"
					:selectedCategories="selectedCategories"
					@openPopupCategory="openPopupCategory"
					@goToCategorySection="goToCategorySection"
				/>
			</div>

			<template v-if="fetched">
				<div v-for="(content, index) in feedsContent" :key="index">
					<div v-if="content.type == 'info' || content.type == 'info_v2'" class="big-wrapper banner-container">
						<banner-info :content="content" :gtmEvent="content.type !== 'info_v2' ? 'homebanner' : 'campaign_banner'" location="discovery" />
					</div>

					<div v-if="content.type == 'deals_attributions'" class="big-wrapper">
						<attribution
							:title="content.title" 
							:cards="content.deals_attributions" 
						/>
					</div>

					<vendors-category
						v-if="content.type == 'vendors' || content.type == 'vendor_attributions'"
						:vendorsData="content"
						:location="feedLists.location"
						:vendorsDataIndex="index+1"
						:isFlexi="content.type == 'vendor_attributions'"
						:isViewAll="content.type == 'vendor_attributions'"
						:page="page"
						@tutorialAction="tutorialAction"
					/>

					<events-component v-if="content.type == 'events'" :eventsData="content" />
					<proposition-component 
						v-if="content.type == 'proposition'" 
						:content="content" 
					/>
					<articles-component
						v-if="content.type == 'blogs'"
						:content="content"
					/>
					<div v-if="content.type == 'separator'" class="big-wrapper">
						<info-message :text="content.label"></info-message>
					</div>
					
					<deals-component
						v-if="content.type == 'deals'"
						dealType="flashdeals"
						:status="content.closest_expiration ? 'flash' : ''"
						:deals="content.deals"
						:endtime="content.closest_expiration"
						:showtimer="true"
						:dealtitle="content.closest_expiration ? 'Flash Sale' : 'Bridestory Deals of the Week'"
						:view_all="true"
					/>

					<div v-if="content.type == 'products'" class="big-wrapper">
						<products-component 
							:cards="content.products.deals"
							:title="content.title"
							:description="content.description"
							:viewAllLink="content.target_href"
							:sectionIndex="index"
						/>
					</div>
				</div>
				<div class="big-wrapper">
					<category-picker
						v-if="showPopup"
						:isDefaultSelection="isDefaultSelection"
						@closePopup="closePopups"
					/>
				</div>
				<tutorial v-if="activePopupIndex === POPUP_INDEX.DISCOVERY_TUTORIAL && isTutorialShow" @finishTutorial="activePopupIndex++" @checkCurrentCity="checkCurrentCity" />
				<curent-city-popup
					v-if="showCurrentCityPopUp"
					ref="manageVendorPopup"
					@closePopupCity="closePopupCity"
					@cancelCity="cancelCity"
					@changeCurrentLocation="changeCurrentLocation"
				/>
			</template>
		</div>
		<loading v-show="!fetched || feedLoading" />
		<configured-popup @closePopup="activePopupIndex++" v-if="activePopupIndex === POPUP_INDEX.CONFIGURABLE && showConfiguredPopup" pageName="discovery"/>
		<popup-nps :survey="survey" @close="closeNpsPopup" v-if="activePopupIndex === POPUP_INDEX.NPS && showNpsPopup"/>
	</div>
</template>

<script>
import attribution from "@/components/discovery/attribution/index.vue";
import BannerSlider from "@/components/bannerSlider/index.vue";
import imageSlider from "@/components/discovery/imageSlider/index.vue";
import mobileImageSlider from "@/components/discovery/mobileImageSlider/index.vue";
import mobileOverview from "@/components/discovery/mobile-overview/index.vue";
import vendorDiscovery from "@/components/discovery/vendorDiscovery/index.vue";
import bannerInfo from "@/components/discovery/banner-info/index.vue";
import loading from "@/components/misc/loading/index.vue";

const categoryPicker = () => import("@/components/discovery/categoryPicker/index.vue");
const currentCity = () => import("@/components/popup/currentCity");
const vendorsCategory = () => import("@/components/discovery/vendorsCategory/index.vue");
const articles = () => import("@/components/discovery/articles/index.vue");
const deals = () => import("@/components/discovery/deals/index.vue");
const products = () => import("@/components/discovery/products/index.vue");
const proposition = () => import("@/components/discovery/proposition/index.vue");
const events = () => import("@/components/discovery/events/index.vue");
const tutorial = () => import("@/components/discovery/tutorial/index.vue");
const infoMessage = () => import("@/components/misc/infoMessage/index.vue");
const popupNps = () => import("@/components/popup/nps/index.vue");
const configuredPopup = () => import('~/components/popup/configured/index.vue');

import adsAPI from "@/api/ads";
import miscAPI from "@/api/misc";
import feedsAPI from "@/api/feeds";
import surveyAPI from '~/api/survey';
import accountsAPI from "@/api/accounts";

import seo from "@/helpers/seoHelper";
import localStorageProvider from '@/helpers/localStorageProvider';
import { observeList, postTracking } from '@/helpers/trackingHelper';
import { scrollStop } from '@/helpers/scroll';

import Cookie from "js-cookie";

export default {
	name: 'DiscoveryPage',
	layout: "defaultBridestory",
	middleware: ["localization"],
	mixins: [seo, localStorageProvider],
	components: {
		"category-picker": categoryPicker,
		"vendors-category": vendorsCategory,
		"curent-city-popup": currentCity,
		"image-slider": imageSlider,
		"mobile-image-slider": mobileImageSlider,
		"events-component": events,
		"deals-component": deals,
		"products-component": products,
		"proposition-component": proposition,
		"articles-component": articles,
		"vendor-discovery-component": vendorDiscovery,
		tutorial: tutorial,
		"mobile-overview": mobileOverview,
		"info-message": infoMessage,
		loading: loading,
		"popup-nps": popupNps,
		"banner-info": bannerInfo,
		"attribution": attribution,
		"configured-popup": configuredPopup,
		BannerSlider
	},
	head() {
		return {
			title: this.$t('vendorDiscovery.meta_title'),
			meta: [
				{
					hid: "description",
					name: "description",
					content: this.$t("vendorDiscovery.meta_desc")
				},
				{
					hid: "keywords",
					name: "keywords",
					content: this.$t('vendorDiscovery.meta_keyword')
				},
				{
					hid: "og:title",
					property: "og:title",
					content: this.$t('vendorDiscovery.meta_title')
				},
				{
					hid: "og:description",
					property: "og:description",
					content: this.$t("vendorDiscovery.meta_desc")
				},
				{
					hid: "og:image",
					property: "og:image",
					content: "https://secure-images.bridestory.com/image/upload/v1458716392/banner/opengraph/tagline.png"
				},
				{
					hid: "twitter:title",
					name: "twitter:title",
					content: this.$t('vendorDiscovery.meta_title')
				},
				{
					hid: "twitter:description",
					name: "twitter:description",
					content: this.$t("vendorDiscovery.meta_desc")
				},
				{
					hid: "twitter:image",
					name: "twitter:image",
					content: "https://secure-images.bridestory.com/image/upload/v1458716392/banner/opengraph/tagline.png"
				},
				{
					hid: "robots",
					name: "robots",
					content: "index, follow"
				},
			],
			__dangerouslyDisableSanitizers: ['script'],
			script: [
				{
					type: 'application/ld+json',
					innerHTML: JSON.stringify({
						"@context": "https://schema.org",
						"@type": "WebSite",
						"url": `https://www.bridestory.com/${this.$i18n.locale === "en"? "" : "id"}`,
						"potentialAction": {
							"@type": "SearchAction",
							"target": {
							"@type": "EntryPoint",
							"urlTemplate": `https://www.bridestory.com${this.$i18n.locale === "en"? "" : "/id"}/search-results?q={search_term_string}`
							},
							"query-input": "required name=search_term_string"
						}
					})
				}
			]
		}
	},
	data() {
		return {
			pickerBottomPopUp: false,
			tempCategories: [],
			isTutorialShow: false,
			showPopup: false,
			showCurrentCityPopUp: false,
			showNpsPopup: false,
			sliderHeight: null,
			fetched: false,
			feedReachLimit: false,
			feedLoading: false,
			survey: null,
			POPUP_INDEX: {
				CONFIGURABLE: 0,
				DISCOVERY_TUTORIAL: 1,
				NPS: 2
			},
			activePopupIndex: 0,
			showConfiguredPopup: false,
			trackingData: []
		}
	},
	computed: {
		feedLists() {
			return this.$store.state.feeds.feeds
		},
		account() {
			return this.$store.state.account.account
		},
		selectedCategories() {
			return this.$store.state.feeds.selectedCategories
		},
		isDefaultSelection() {
			return this.$store.state.feeds.isDefaultSelection
		},
		banners() {
			return this.$store.state.banners.banners
		},
		askCurrentLocation() {
			return this.$store.state.account.wedding_details.ask_current_location
		},
		user() {
			return this.$store.state.account.account && this.$store.state.account.account.account.data;
		}
	},
	watch: {
		feedLists: function(newVal, oldVal) {
			let contents = newVal.contents;
			this.feedsContent = [...this.feedsContent, ...contents];
			this.handleObserveList();
		},
		'$store.state.system.configurablePopupEmpty': function(niu, old) {
			if(niu) this.activePopupIndex = 1;
		}
	},
	methods: {
		buildSliderObject(banners) {
			let images = banners.map(function(img) {
				return {
					url: img.imageUrl,
					title: img.title,
					link: img.targetHref
				}
			});
			return images
		},
		trackBanner(image) {
			dataLayer.push({
				discovery: {
					category: 'discovery',
					action: 'click_banner',
					label: image.title
				}
			})
		},
		imageData(banners) {
			let images = banners.map(function(img){
				return img.imageUrl;
			});
			return images
		},
		async checkForSurvey() {
			if(this.account && Cookie.get('BSID')) {
				const surveyApi = new surveyAPI(this.$axios)
	
				try {
					let { data } = await surveyApi.getSurvey('customer-satisfaction');
					this.survey = data.data
				} catch(error) {
					this.survey = null
				}
	
				if(this.survey !== null) {
					this.showNpsPopup = true
				}
			}
		},
		closePopupCity: function() {
			this.showCurrentCityPopUp = false

			this.updateViewPopup({
				currentCity: false
			})
		},
		cancelCity: function() {
			this.showCurrentCityPopUp = false

			this.updateViewPopup({
				currentCity: false
			})
		},
		closeNpsPopup() {
			this.showNpsPopup = false;
		},
		closePopups() {
			this.showPopup = false;
			this.fetched = false;
			this.page = 1;
			this.feedsContent = [];
			this.fetchFeeds().then(obj => {
				if (this.feedsContent && this.feedsContent.length > 2) {
					document.body.style.overflow = "auto"
					this.fetched = true;
				}
				this.isTutorialShow = false;
			});
		},
		checkCurrentCity() {
			this.showCurrentCityPopUp =
				typeof this.$cookies.get("BS.user.viewPopup").currentCity ===
				"boolean"
					? this.$cookies.get("BS.user.viewPopup").currentCity
					: this.askCurrentLocation
		},
		tutorialAction() {
			if (Cookie.get("BS.user.viewTutorial") === undefined) {
				Cookie.set("BS.user.viewTutorial", true, {
					domain: ".bridestory.com",
					expires: 365,
					path: "/"
				})
				this.isTutorialShow = true;
			} else {
				this.isTutorialShow = false;
			}
						
		},
		openPopupCategory() {
			this.showPopup = true
			document.body.style.overflow = "hidden"
		},
		showPopupCategory() {
			this.showPopup = this.isDefaultSelection;
			let isMobile = window.screen.width < 769;
			if (this.showPopup && isMobile) {
				this.$store.dispatch('setCatPicker',true)
			}
		},
		changeCurrentLocation() {
			this.updateViewPopup({
				currentCity: false
			})

			let feedsApi = new feedsAPI(this.$axios)
			this.$store.dispatch('setFeedsLoading', true)
			this.page = 1;

			this.fetchFeeds();
		},
		updateViewPopup(param) {
			let _viewPopup = this.$cookies.get("BS.user.viewPopup")
			let data = Object.assign({}, _viewPopup, param)
			Cookie.set("BS.user.viewPopup", JSON.stringify(data), {
				domain: ".bridestory.com",
				maxAge: 31536000,
				path: "/"
			})
		},
		countDay(closest_expiration) {
			let now = this.$dayjs(new Date())
			let t = this.$dayjs(closest_expiration)
			let diff = t.diff(now, "days")
			return diff
		},
		browserWidth: function() {
			this.sliderHeight =
				window.innerWidth ||
				document.documentElement.clientWidth ||
				document.body.clientWidth
			if (this.sliderHeight > 768) {
				return 200
			} else {
				return null
			}
		},
		showMore() {
			if (window.pageYOffset >= (document.body.offsetHeight - 2000) && !this.feedLoading && !this.feedReachLimit && this.feedsContent && this.feedsContent.length) {
				this.feedLoading = true;
				this.page = this.page + 1;
				return this.fetchFeeds().then(res => {
					this.feedLoading = false;
					this.feedReachLimit = res.data && res.data.feeds && res.data.feeds.contents && !res.data.feeds.contents.length ? true : false;
				});
			}
			this.showConfiguredPopup = true;
		},
		sendImpressions() {
			let actorId = this.user ? this.user.uuid : Cookie.get('BSUUID');
			
			if(this.feedLists && this.feedLists.advertisements && this.feedLists.advertisements.ids.length && !navigator.userAgent.toLowerCase().includes('googlebot')){
				let data = {
					tracking_ids: this.feedLists.advertisements.ids || null,
					actor_id: actorId,
					client_id: Cookie.get('BSUUID'),
					impression_meta: {
						screen: "discovery",
						page: this.page
					}
				}
				let adsApi = new adsAPI(this.$axios);
				return adsApi.sendImpression(data).then(response => {}).catch((err) => {
					console.error(err)
				});
			}
		},
		fetchFeeds() {
			let isMobile = this.$device.isMobile ? true : false;
			let defaultCategories = Cookie.get('BS.defaultSelectedCategories') ? JSON.parse(Cookie.get('BS.defaultSelectedCategories')).join(',') : undefined;

			let feedsApi = new feedsAPI(this.$axios);
			return feedsApi.getHomeFeeds({limit: this.limit, page: this.page, is_mobile: isMobile, ads: true }, defaultCategories).then(feeds => {
				this.$store.dispatch("setFeeds", feeds.data.feeds)
				// this.sendImpressions()
				return feeds;
			})
		},
		fetchBanners() {
			let miscApi = new miscAPI(this.$axios, this.req);
			return miscApi.getBanners({
				isOnHomestream: true,
				isOnBlog: false,
				isTraditional: false,
				status: "active",
				isDesktop: false,
				isMobile: true
			})
			.then(banners => {
				for (let banner of banners.data.banners) {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: 'v2_banner_load',
						v2_bannerId: banner.title + ';' + banner.id
					});
				}

				this.$store.dispatch("setBanners", banners.data.banners);
			})
		},
		fetchCategories() {
			let miscApi = new miscAPI(this.$axios, this.req);
			return miscApi.getCategories().then(categories => {
				this.$store.dispatch('setCategories', categories.data.categories);
				this.$store.dispatch('setDefaultSelection', categories.data.is_default_selection);
			});
		},
		fetchBudgets() {
			let miscApi = new miscAPI(this.$axios, this.req);
			return miscApi.getBudgets().then(budgets => {
				this.$store.dispatch("setBudgets", budgets.data.budgets)
			})
		},
		fetchCities() {
			let feedsApi = new feedsAPI(this.$axios, this.req, this.route);
			return feedsApi.getCities().then(cities => {
				this.$store.dispatch("setCities", cities.data.cities)
			})
		},
		fetchCountries() {
			let accountsApi = new accountsAPI(this.$axios, this.req);
			return accountsApi.getVendorCountries().then(countries => {
				this.$store.dispatch("setCountries", countries.data.countries)
			})
		},
		initData() {
			if(this.banners && this.banners.length) {
				for (let banner of this.banners) {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: 'v2_banner_load',
						v2_bannerId: banner.title + ';' + banner.id
					});
				}
			}
			return Promise.all([
				this.fetchBudgets(),
				this.fetchCities(),
				this.fetchCountries()
			]);
		},
		checkWeddingDetails() {
			if(this.account && this.account.type == 'user') {
				let accountsApi = new accountsAPI(this.$axios);
	
				if (!this.$cookies.get("BS.user.viewPopup")) {
					this.$cookies.set(
						"BS.user.viewPopup",
						{},
						{
							domain: ".bridestory.com",
							maxAge: 31536000,
							path: "/"
						}
					)
				}

				return accountsApi.getWeddingDetails().then(response => {
					if (this.isDefaultSelection || this.showPopup) {
						this.showCurrentCityPopUp = false
					} else {
						this.showCurrentCityPopUp =
							typeof this.$cookies.get("BS.user.viewPopup")
								.currentCity === "boolean"
								? this.$cookies.get("BS.user.viewPopup")
										.currentCity
								: response.data.meta.ask_current_location
						
					}
					this.$store.dispatch(
						"setWeddingDetails",
						response.data.wedding_details
					)
					this.$store.dispatch("setAskCurrentLocation", response.data.meta)
				}).catch(err => {
					if(err.response && err.response.status !== 401){ 
						window.location.href = "/wedding-details";
					}else {
						Cookie.remove('BSID', {domain: '.bridestory.com'})
						this.$store.dispatch('logout');
					}
				})
			} else {
				return Promise.resolve(true);
			}
		},
		goToCategorySection(slug) {
			let element = document.querySelector(`#${slug}`);

			if(element) {
				element = element.offsetTop;
				this.$nuxt.$loading.finish();
				window.scrollTo({
					top: element - 100,
					behavior: "smooth"
				});
			} else {
				this.$nuxt.$loading.start();
				this.page = this.page + 1;
				return this.fetchFeeds().then(res => {
					this.feedReachLimit = res.data && res.data.feeds && res.data.feeds.contents && !res.data.feeds.contents.length ? true : false;
					this.goToCategorySection(slug);
				});
			}
		},
		handleTrackingData({id, type, section, isEmpty=false}) {
			if (isEmpty) {
				this.trackingData.splice(0, this.trackingData.length);
			} else {
				this.trackingData.push({
					id: id,
					type: type,
					section: section
				});
			}
		},
		handleObserveList() {
			observeList({
				containerElementId: 'discovery-page',
				listElementIdentifier: '[data-tracking-id]',
				callback: (params) => this.handleTrackingData(params)
			});
		},
		handlePostTracking() {
			postTracking({
				context: this,
				type: 'ctr',
				trackingData: this.trackingData,
				trackingMeta: {
					current_screen: 'discovery'
				},
				onSuccess: (params) => this.handleTrackingData(params)
			})
		},
		handleTracking() {
			setTimeout(()=>{
				if(this.feedsContent.length > 0) {
					this.handleObserveList();
					setTimeout(()=>{
						this.handlePostTracking();
					}, 500)

					scrollStop({
						onScrollStopCallback: () => { 
							this.handlePostTracking();
						}
					})
				}
			}, 500);
		}
	},
	beforeMount () {
		console.log('banners => ', this.$store.state.banners.banners);
		
		Cookie.set('BS.previous-page', 'discovery', {domain : '.bridestory.com', path : '/'});
		this.setLocalStorage('page.name', "DiscoveryPage")
		this.dataLayerPageName("DiscoveryPage");
		
		let _this = this;
		this.initData().then(res => {
			_this.fetched = true;
			this.$nextTick(() => {
				_this.$nuxt.$loading.finish();
			}, 500);
		});
		
		this.pageView({
			v2_pageProperties: {
				category: 'discovery',
				subCategory: undefined,
				properties: undefined
			}
		})
	},
	async mounted() {
		this.handleTracking();
		window.addEventListener("scroll", this.showMore)
		this.$store.dispatch("setDealsDetail", null)
		this.sliderHeight =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth
		window.addEventListener("resize", this.browserWidth)
		this.checkWeddingDetails();
		this.tutorialAction();
		await this.checkForSurvey()
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.showMore);
		window.removeEventListener("resize", this.browserWidth)
	},
	async asyncData({ $axios, store, app, req, redirect, query }) {
		if(query.tokenId) return redirect(301, '/forgotpassword/change', query)

		let miscApi = new miscAPI($axios, req);

		let defaultSelectedCategories = app.$cookies.get('BS.defaultSelectedCategories')
		try {
			let _categories = await miscApi.getCategories()
			store.dispatch('setCategories', _categories.data.categories);
			store.dispatch('setDefaultSelection', _categories.data.is_default_selection);

			defaultSelectedCategories = app.$cookies.get('BS.defaultSelectedCategories')
			if(!defaultSelectedCategories) {
				defaultSelectedCategories = _categories.data.categories.filter(cat => cat.is_selected == true).map(function(cat){
					return cat.slug;
				});
				app.$cookies.set('BS.defaultSelectedCategories', JSON.stringify(defaultSelectedCategories), {maxAge: 60 * 60 * 24 * 365 , domain : '.bridestory.com', path : '/'})
			}
			let selectedCategory = []
			if(app.$cookies.get('BSID')){
				selectedCategory = _categories.data.categories.filter(cat => cat.is_selected == true)
			}else{
				selectedCategory = _categories.data.categories.filter((category) => defaultSelectedCategories.includes(category.slug))
			}
			store.dispatch('setFeedsSelectedCategories', selectedCategory);
		} catch (err) {
			console.error(err)
		}

		let page = 1
		let isMobile = app.$device.isMobile
		let limit = isMobile ? 3 : 5
		let feedsContent = []
		try {
			let feedsApi = new feedsAPI($axios);
			let token =  app.$cookies.get("BSID")
			let _feeds

			if(token) {
				_feeds = await feedsApi.getHomeFeeds({limit: limit, page: page, is_mobile: isMobile, ads: true })
			}else{
				_feeds = await feedsApi.getHomeFeeds({limit: limit, page: page, is_mobile: isMobile, ads: true }, defaultSelectedCategories.join(','))
			}
			
			store.dispatch("setFeeds", _feeds.data.feeds)
			feedsContent = [...feedsContent, ..._feeds.data.feeds.contents];
		} catch (err) {
			console.error(err)
		}
		
		return {
			page,
			limit,
			feedsContent
		}
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />