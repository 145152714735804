<template>
	<div :class="content.target_url && 'banner-click'" id="banner-info-comp" @click="onBannerClick">
		<img v-if="!content.content" class="campaign-image" :src="getImageUrl(bgImage).imgSrc" />
		<div v-else v-bind:style="`background-image: url(${getImageUrl(bgImage).imgSrc})`" class="banner">
			<img v-if="content.icon" :src="getImageUrl(content.icon).imgSrc" alt="banner-logo">
			<p v-if="content.content" v-html="sanitizeHtml(content.content)"></p>
		</div>
	</div>
</template>

<script>
import imageHelper from "@/helpers/imageHelper";

export default {
	name: "BannerInfoComp",
	props: {
		content: {
			type: Object,
			required: true
		},
		gtmEvent: {
			type: String,
			required: false,
			default: ''
		},
		location: {
			type: String,
			required: false,
			default: ''
		}
	},
	mixins: [imageHelper],
	data() {
		let background = this.$device.isDesktop ? 'https://alexandra.bridestory.com/image/upload/c_thumb,f_auto,fl_progressive,q_80/assets/ticker2x-SyJWIKSD8.png' : 'https://alexandra.bridestory.com/image/upload/c_thumb,f_auto,fl_progressive,q_80,w_500/assets/ticker2xmobile-HkynuKBw8.png';

		if (this.content.banner) {
			if(this.content.banner.desktop.url) {
				background = this.$device.isDesktop ? this.content.banner.desktop.url : this.content.banner.mobile.url;
			} else {
				background = this.$device.isDesktop ? this.content.banner.desktop : this.content.banner.mobile;
			}
		} else if (this.content.background_image) {
			background = this.$device.isDesktop ? this.content.background_image.desktop.url : this.content.background_image.mobile.url;
		} else if (this.content.background) {
			background = this.$device.isDesktop ? this.content.background.web : this.content.background.mobile;
		}

		return {
			bgImage: background,
		}
	},
	methods: {
		onBannerClick() {
			if(!this.content.target_url) {
				return;
			}

			if(this.gtmEvent) {
				if(this.gtmEvent === 'homebanner') {
					dataLayer.push({
						event: this.gtmEvent,
						homebanner: {
							label: this.content.campaign_name,
							category: "ticker_banner",
							action: "click"
						}
					})
				} else {
					dataLayer.push({
						event: this.gtmEvent,
						campaignBannerProperties: {
							action: "click",
							location : this.location,
							bannerTitle : this.content.campaign_name,
							redirectUrl : this.content.target_url,
						}
					})
				}
			}

			window.open(this.content.target_url, "_blank");
		}
	},
	mounted() {
    window.dataLayer = window.dataLayer || [];
		if(this.gtmEvent && this.gtmEvent !== 'homebanner') {
			dataLayer.push({
				event: this.gtmEvent,
				campaignBannerProperties: {
					action: "load",
					location : this.location,
					bannerTitle : this.content.campaign_name,
					redirectUrl : this.content.target_url,
				}
			})
		}
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />