<template>
	<client-only>
		<div class="is-loading" :class="{'bg-white' : withWrapper, 'full': fullScreen}">
			<image-style :hideLoadingEffect="true" :isLazyLoad="true" :src="src" :width="40" :height="40" alt="loader" />
		</div>
	</client-only>
</template>

<script>
import imageStyle from '~/components/decorators/imageStyle.vue';

export default {
	name: "loadingComponent",
	props: ["withWrapper", "fullScreen"],
	components: {
		imageStyle
	},
	data() {
		return {
			src : 'https://alexandra.bridestory.com/image/upload/assets/images/desktop/loaderfaster.gif'
		}
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />