const url = process.env.BACKEND_BASE_URL + '/ms/survey';

export default class SurveyAPI {
	constructor($axios) {
		this.$axios = $axios
	}

	getSurvey(key) {
		return this.$axios.request({
			'url': url + '/v1/surveys/' + key,
			'method': 'GET',
			'headers': {
				'Content-Type': 'application/json'
			}
		})
	}

	postSurvey(key, data) {
		return this.$axios.request({
			'url': url + '/v1/surveys/' + key,
			'method': 'POST',
			data,
			'headers': {
				'Content-Type': 'application/json'
			}
		})
	}

	incrementSurveyViewCount(key) {
		return this.$axios.request({
			'url': url + '/v1/surveys/' + key + '/view',
			'method': 'POST',
			'headers': {
				'Content-Type': 'application/json'
			}
		})
	}
}
