<template>
	<div class="banner-slider">
		<template v-if="banners && banners.length">
			<BannerMobile
				v-if="$device.isMobile"
				:banners="banners"
				@onClick="handleClick"
				@onSlide="handleSlide"
			/>
			<BannerDesktop
				v-else
				:banners="banners"
				:autoPlay="autoPlay"
				@onClick="handleClick"
				@onSlide="handleSlide"
			/>
		</template>
	</div>
</template>

<script>
import BannerDesktop from "@/components/bannerSlider/desktop/index.vue"
import BannerMobile from "@/components/bannerSlider/mobile/index.vue"
import Cookie from "js-cookie"
import CampaignAPI from "@/api/campaign"
import ViewAPI from "@/api/view"

export default {
	name: "BannerSlider",
	props: ["pageName", "autoPlay"],
	components: {
		BannerDesktop,
		BannerMobile
	},
	data() {
		return {
			banners: []
		}
	},
	mounted() {
		this.getBanners()
	},
	methods: {
		getBanners() {
			let campaignAPI = new CampaignAPI(this.$axios)
			campaignAPI.getBanners(this.pageName).then(res => {
				this.banners = res.data.banners
			})
		},
		handleClick(banner) {
			this.handleTrackingPayload("banner_conversion", banner)
		},
		handleSlide(banner) {
			this.handleTrackingPayload("banner_impression", banner)
		},
		handleTrackingPayload(type, banner) {
			let viewApi = new ViewAPI(this.$axios)
			const account = this.$store.state.account.account
			const userId =
				(account &&
					account.account.data &&
					account.account.data.uuid) ||
				Cookie.get("BSUUID")

			let payloadView = {
				user_id: userId,
				client_id: Cookie.get("BSUUID"),
				entity_id: banner.id,
				entity_type: type,
				previous_page: "",
				meta: {
					current_screen: this.pageName,
					order_priority: banner.order_priority
				}
			}
			return viewApi.postView(payloadView)
		}
	}
}
</script>
