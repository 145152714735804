export default class CampaignAPI {
	constructor($axios) {
    	this.baseURL = process.env.BACKEND_BASE_URL;
		this.$axios = $axios
		this.campaignPath = 'ms/campaign/api/v1'
	}

	getListCampaigns(params) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.campaignPath}/campaigns`,
			method: "GET",
			params: params,
			headers: {
				"Content-Type" : "application/json"
			}
		})
	}

	getCampaignTags() {
		return this.$axios.request({
			url: `${this.baseURL}/${this.campaignPath}/tags`,
			method: "GET",
			headers: {
				"Content-Type" : "application/json"
			}
		})
	}

	
	getCampaignPreview(preview_id) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.campaignPath}/campaigns/preview/${preview_id}`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getCampaignDetail(slug) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.campaignPath}/campaigns/${slug}`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getRelatedCampaigns(id) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.campaignPath}/campaigns/${id}/related`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}
	
	getListPromoProduct(params){
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.campaignPath}/promotions`,
			params: params,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}
	getBanners(pageName, platform = 'desktop'){
		return this.$axios.request({
			method:"GET",
			url: `${this.baseURL}/${this.campaignPath}/banners/${pageName}`,
			params: {
				platform
			},
			headers: {
				"Content-Type": "application/json"
			}
		})
	}
}
